import "./style.css"
import { useEffect } from 'react';
import { useInView } from "react-intersection-observer";

export default function Buy() {
  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: false,
    rootMargin: '0px 0px',
    threshold: 0.9,
  })
  
  useEffect(() => {
    if (inView) window.location.replace('#Buy');

  }, [inView])

  return (
    <div ref={ref} id="Buy">
      <h1 className="title">Formatos</h1>
      <div className="link-container">
        <div className="link-data">
          <h2 className="subtitle">E-book</h2>
          <img src="/images/cover-ebook.jpg" alt="Imagem do ebook" className="image"/>
          <h3 className="price">R$25</h3>
          <a className="button" 
          href="https://hotmart.com/pt-br/marketplace/produtos/os-processos-das-abstracoes-mentais-na-construcao-do-conhecimento-musical-a-partir-de-piaget-uma-revisao-integrativa/Y90761975O">
          Saiba mais!</a>
        </div>
        <div className="link-data">
          <h2 className="subtitle">Físico</h2>
          <img src="/images/cover.jpg" alt="Imagem do livro físico" className="image"/>
          <h3 className="price">R$62</h3>
          <a className="button hotmart-fb hotmart__button-checkout" onclick="return false;" href="https://pay.hotmart.com/V97999515S?checkoutMode=2">
                Aproveite!
          </a>
        </div>
      </div>
    </div>
  )
}
